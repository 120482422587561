<template>
  <CCard>
    <CCardHeader>
      {{!this.isEdit ? this.$t('Добавление подписной страницы') : this.$t('Редактирование подписной страницы') }}
    </CCardHeader>
    <CCardBody>
      <CForm @submit.prevent="save(model)">
        <CInput
            :label="this.$t('Адрес') + '(uri)'"
            horizontal
            v-model.lazy="model.code"
            :description="this.$t('То, что идёт после https://evli.in/... Только английские буквы, цифры и тире')"
            pattern="^[a-zA-Z\d-]+$"
        />
        <CInputFile
            :label="this.$t('Картинка наверху')"
            horizontal
            @change="file"
        />
        <CInput
            hidden
            horizontal
            v-model.lazy="model.top_img"
        />
        <img :src="model.top_img" class="calendar_page_preview">
        <span class="delete_top_img" href="#" @click="deleteImg" v-if="model.top_img">{{$t('удалить картинку')}}</span>
        <CInput
            :label="this.$t('Заголовок')"
            horizontal
            v-model.lazy="model.h1"
        />
        <CRow form class="form-group">
          <CCol sm="3">
            {{ $t('Текст под заголовком') }}
          </CCol>
          <CCol sm="9">
            <vue-editor
                :label="this.$t('Текст под заголовком')"
                v-model.lazy="model.text" id="editor"
                :editorOptions="editorOptions"
                placeholder=""
                class="col-sm-9"
            ></vue-editor>
          </CCol>
        </CRow>
        <CInput
            :label="this.$t('Текст на подписной кнопке')"
            horizontal
            v-model.lazy="model.button_text"
            required
        />
<!--        <CTextarea-->
<!--            :description="$t('Google, Facebook, Yandex и другие')"-->
<!--            :label="$t('Скрипты')"-->
<!--            placeholder=""-->
<!--            horizontal-->
<!--            rows="9"-->
<!--            v-model.lazy="model.scripts"-->
<!--        />-->
        <CInput
            hidden
            horizontal
            v-model.lazy="model.calendar_id"
            required
        />
        <CInput
            hidden
            horizontal
            v-model.lazy="model.id"
        />
        <router-link :to="'/calendar/' + this.$route.params.cid"><CButton type="submit" size="sm" color="dark">{{ this.$t('Отмена') }}</CButton></router-link>
        <CButton type="submit" size="sm" color="info">{{!this.isEdit ? this.$t('Добавить') : this.$t('Обновить') }}</CButton>
      </CForm>
      <notifications group="calendar" />
    </CCardBody>
  </CCard>
</template>

<script>
import axios from '@/modules/axios'
import { VueEditor, Quill } from 'vue2-editor'
import Emoji from 'quill-emoji'
import 'quill-emoji/dist/quill-emoji.css'
Quill.register(
    {
        'formats/emoji': Emoji.EmojiBlot,
        'modules/short_name_emoji': Emoji.ShortNameEmoji,
        'modules/toolbar_emoji': Emoji.ToolbarEmoji,
        'modules/textarea_emoji': Emoji.TextAreaEmoji
    },
    true
)

export default {
  name: 'CalendarAddEvent',
  components: {
    VueEditor
  },
  data () {
    const editorColors = [
      '#000000',
      '#e60000',
      '#ff9900',
      '#ffff00',
      '#008a00',
      '#0066cc',
      '#9933ff',
      '#ffffff',
      '#facccc',
      '#ffebcc',
      '#ffffcc',
      '#cce8cc',
      '#cce0f5',
      '#ebd6ff',
      '#bbbbbb',
      '#f06666',
      '#ffc266',
      '#ffff66',
      '#66b966',
      '#66a3e0',
      '#c285ff',
      '#888888',
      '#a10000',
      '#b26b00',
      '#b2b200',
      '#006100',
      '#0047b2',
      '#6b24b2',
      '#444444',
      '#5c0000',
      '#663d00',
      '#666600',
      '#003700',
      '#002966',
      '#3d1466'
    ]
    return {
      model: {
        id: 0,
        top_img: '',
        h1: '',
        text: '',
        button_text: this.$t('Подписаться'),
        code: '',
        oldCode: '',
        scripts: '',
        calendar_id: 0
      },
      editorOptions: {
        modules: {
          toolbar: {
            container: [
              [{ header: [false, 1, 2, 3, 4, 5, 6] }],
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ color: editorColors }, { background: editorColors }],
              ['link'],
              ['emoji'],
              ['clean']
            ],
            handlers: {
                emoji: function () {}
            }
          },
          short_name_emoji: true,
          toolbar_emoji: true,
          textarea_emoji: true
        }
      }
    }
  },
  created() {
    this.getPage()
  },
  computed: {
    isEdit () {
      return this.$route.params.code
    }
  },
  methods: {
    save() {
      const url = this.isEdit ? '/page/edit' : '/page/add'
      this.model.calendar_id = parseInt(this.$route.params.cid)
      axios.post(url, this.model, {
        headers: {'content-type': 'application/json'}
      }).then(() => {
        this.$router.push('/calendar/' + this.$route.params.cid)
      }).catch((error) => {
        this.$notify({group: 'calendar', text: error.response.data.message, type: 'error'})
      })
    },
    getPage() {
      if(!this.isEdit) return
      axios.get('/page/info', {
        params: {code: this.$route.params.code}
      }).then((res) => {
        this.model = res.data.data;
        this.model.oldCode = res.data.data.code
      }).catch((error) => {
        this.$notify({ group: 'calendar', text: error.response.data.message, type: 'error' })
      })
    },
    file(files) {
      const file = files[0]
      const img = document.getElementsByClassName("calendar_page_preview")[0];
      img.file = file;

      const reader = new FileReader();
      reader.onload = (function(aImg) { return function(e) { aImg.src = e.target.result; }; })(img);
      reader.readAsDataURL(file);

      const formData = new FormData()
      formData.append('file', file, file.name)

      axios.post('/page/upload-img', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        this.model.top_img = response.data.data[0]
      }).catch((error) => {
        this.$notify({group: 'calendar', text: error.response.data.message, type: 'error'})
      })
    },
    deleteImg() {
      this.model.top_img = ''
      return false
    }
  }
}
</script>
<style lang="scss">
.calendar_page_preview {
  width: 100px;
  margin-bottom: 20px;
}
form button {
  margin-right: 2em;
}
textarea {
  white-space: pre-wrap;
}

#editor {
  width: 100%;
  max-width: 100%;
  padding: 0;
}
.delete_top_img {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 2em;
}
</style>
